import React, {useState, useRef, fileUpload, setSelectedFile, setName, submitForm, name} from 'react'
import {
  StyleSheet,
  Text,
  View,
  Image,
} from 'react-native';
import {Form, Button, Dropdown} from 'react-bootstrap'
import Swal from 'sweetalert2';


class UserProfilePage extends React.Component {

    constructor(props) {
        super(props);
        // use this.state here only. In all other places, use this.setState()
        this.state = {
            username: 'username',
            email: 'email',
            userTokenCount: 'userTokenCount',
            isActivated: 'False'
        };
        // see below url for this type of binding:
        // https://stackoverflow.com/questions/32317154/react-uncaught-typeerror-cannot-read-property-setstate-of-undefined
        this.loadTokens = this.loadTokens.bind(this);
    } // constructor

     componentDidMount () {
        const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': token
            },
        }
        fetch('/auth/info', requestOptions)
        .then(response => response.json())
        .then(data => {
            this.setState({
                "username": data.username,
                "email": data.email,
                "userTokenCount": data.userTokenCount
            })
        })
    }


    loadTokens () {
        Swal.fire({
            title: 'Purchase Additional Tokens?',
            text: "Tokens are currently free.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Get Free Tokens",
        }).then((result) => {
            if (result.isConfirmed) {

                if (this.state.userTokenCount >= 23) {
                    Swal.fire({
                        title: 'Too many tokens!',
                        text: "You have too many tokens. Tokens are capped at 23-25 tokens",
                        icon: 'warning',
//                         showCancelButton: true,
//                         confirmButtonColor: '#3085d6',
//                         cancelButtonColor: '#d33',
//                         confirmButtonText: "Purchase Tokens",
                    }) // Swal
                } // if

                else {
                    const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
                    // When you make a POST request, you have to encode the data that forms the body of the request in some way.
                    // https://stackoverflow.com/questions/4526273/what-does-enctype-multipart-form-data-mean
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Authorization': token,
                        }, // headers
                    } // requestOptions
                    fetch('/auth/token_adder', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            "userTokenCount": data.userTokenCount
                        }) // setState
                    }) // then
                    Swal.fire({
                        title: "Enjoy Your Tokens!",
                        text: "Tokens have been added to your account!",
                        timer: 2000,
                        showConfirmButton: false,
                        type: "success"
                    }); // Swal
                } // else

            } // if
        }) // then
    } // loadTokens


    render() {
        return (
            <View style={styles.container}>
                <View style={styles.header}>
                <View style={styles.headerContent}>
    {/*                 <Image style={styles.avatar} source={{uri: 'https://bootdey.com/img/Content/avatar/avatar6.png'}}> */}
    {/*                 </Image> */}
                    <Text style={styles.name}>Username: {this.state.username}</Text>
                    <Text style={styles.userInfo}>Email: {this.state.email}</Text>
                    <Text style={styles.userInfo}>Available Tokens: {this.state.userTokenCount}</Text>
                    <br/>
                    <Button onClick={this.loadTokens}>Get More Tokens</Button>
                    <br/>
                    <br/>
                    <br/>
                    <Text style={styles.name}>Need a Custom Solution? Inquire At:</Text>
                    <Text style={styles.name}>latticemixer@gmail.com</Text>
                </View>
            </View>

{/*           <View style={styles.body}> */}
{/*             <View style={styles.item}> */}
{/*               <View style={styles.iconContent}> */}
{/*                 <Image style={styles.icon} source={{uri: 'https://img.icons8.com/color/70/000000/cottage.png'}}/> */}
{/*               </View> */}
{/*               <View style={styles.infoContent}> */}
{/*                 <Text style={styles.info}>Home</Text> */}
{/*               </View> */}
{/*             </View> */}

{/*             <View style={styles.item}> */}
{/*               <View style={styles.iconContent}> */}
{/*                 <Image style={styles.icon} source={{uri: 'https://img.icons8.com/color/70/000000/administrator-male.png'}}/> */}
{/*               </View> */}
{/*               <View style={styles.infoContent}> */}
{/*                 <Text style={styles.info}>Settings</Text> */}
{/*               </View> */}
{/*             </View> */}

{/*             <View style={styles.item}> */}
{/*               <View style={styles.iconContent}> */}
{/*                 <Image style={styles.icon} source={{uri: 'https://img.icons8.com/color/70/000000/filled-like.png'}}/> */}
{/*               </View> */}
{/*               <View style={styles.infoContent}> */}
{/*                 <Text style={styles.info}>News</Text> */}
{/*               </View> */}
{/*             </View> */}

{/*             <View style={styles.item}> */}
{/*               <View style={styles.iconContent}> */}
{/*                 <Image style={styles.icon} source={{uri: 'https://img.icons8.com/color/70/000000/facebook-like.png'}}/> */}
{/*               </View> */}
{/*               <View style={styles.infoContent}> */}
{/*                 <Text style={styles.info}>Shop</Text> */}
{/*               </View> */}
{/*             </View> */}
{/*           </View> */}

      </View>
    );
  }
}

const styles = StyleSheet.create({
  header:{
    backgroundColor: "#DCDCDC",
  },
  headerContent:{
    padding:30,
    alignItems: 'center',
  },
  avatar: {
    width: 130,
    height: 130,
    borderRadius: 63,
    borderWidth: 4,
    borderColor: "white",
    marginBottom:10,
  },
  name:{
    fontSize:22,
    color:"#000000",
    fontWeight:'600',
  },
  userInfo:{
    fontSize:16,
    color:"#778899",
    fontWeight:'600',
  },
  body:{
    backgroundColor: "#778899",
    height:500,
    alignItems:'center',
  },
  item:{
    flexDirection : 'row',
  },
  infoContent:{
    flex:1,
    alignItems:'flex-start',
    paddingLeft:5
  },
  iconContent:{
    flex:1,
    alignItems:'flex-end',
    paddingRight:5,
  },
  icon:{
    width:30,
    height:30,
    marginTop:20,
  },
  info:{
    fontSize:18,
    marginTop:20,
    color: "#FFFFFF",
  }
});

export default UserProfilePage
