import React, {useState} from 'react'
import {Form, Button, Alert} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {login} from '../auth.jsx'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'


const ResetPasswordPage = () => {

    // using the react-hook-form
    const {register, watch, handleSubmit, reset, formState:{errors}} = useForm();

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [serverResponse, setServerResponse] = useState('')
    const [serverStatus, setServerStatus] = useState('')
    const [show, setShow] = useState(false)

    const params = useParams();
    const token = JSON.stringify(params.id)

    // use react-router to redirect to another page - just push the next page to this history
    const history = useHistory()


    const submitForm = (data) => {

        if (data.password === data.confirmPassword) {

            const body = {
                password: data.password
            } // body

            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            } // requestOptions

            fetch('/auth/reset_password/'+token, requestOptions)
                .then(response =>  response.json().then(response_data => (
                    {"status": response.status, "json": response_data}
                 )))
                .then(response_data => {
                    console.log(response_data["json"])
                    setServerResponse(response_data["json"].message)
                    setShow(true)
                    console.log("response_status = ", response_data["status"])
                    if (response_data["status"] === 201) {
                        history.push('/login')
                    };
                })
                .catch(err => console.log(err))
                reset()

        } // if
    } // submitForm


    return(

        <div className="signup container">
            <div className="form">

            <h1>Reset Password Page</h1>

                <form>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"
                            placeholder="Your password"
                            {...register("password", {required:true, minLength:8})}
                        />
                        {errors.password && <span style={{color:"red"}}>Password is required. </span>}
                        {errors.password?.type==="minLength" && <span style={{color:"red"}}>
                                                    Password must be at least 8 characters. </span>}
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password"
                            placeholder="Your confirmed password"
                            {...register("confirmPassword", {required:true, minLength:8})}
                        />
                        {errors.confirmPassword && <span style={{color:"red"}}>Must confirm password. </span>}
                        {errors.confirmPassword?.type==="minLength" && <span style={{color:"red"}}>
                                                    Password must be at least 8 characters. </span>}
                    </Form.Group>

                    <br></br>
                    <Form.Group>
                        <Button as="sub" variant="primary" onClick={handleSubmit(submitForm)}>Reset Password</Button>
                    </Form.Group>
                </form>

                <br/>

                { show ?
                    <>
                    { serverStatus === 201 ?
                        <Alert variant="success" onClose={() => {setShow(false)}} dismissible>
                            {serverResponse}
                        </Alert>
                        :
                        <Alert variant="danger" onClose={() => {setShow(false)}} dismissible>
                            {serverResponse}
                        </Alert>
                     }
                    </>
                    : ''
                }

            </div>
        </div>

    ) // return

}

export default ResetPasswordPage
