import React, {useState} from 'react'
import {Form, Button, Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {login} from '../auth.jsx'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2';


const ForgotPasswordPage = () => {

    // using the react-hook-form
    const {register, watch, handleSubmit, reset, formState:{errors}} = useForm();

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [serverResponse, setServerResponse] = useState('')
    const [serverStatus, setServerStatus] = useState('')
    const [show, setShow] = useState(false)

    const submitForm = (data) => {

        const body = {
            email: data.email
        } // body

        const requestOptions = {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(body)
        } // requestOptions

        fetch('/auth/forgot_password', requestOptions)
            .then(response =>  response.json().then(response_data => (
                {"json": response_data, "status": response.status, }
             ))) // then
            .then(response_data => {
                if (response_data["status"] === 201){
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        titleText: 'An email has been sent to '+data.email+' with further instructions.',
                        showConfirmButton: false,
                        timer: 9000
                    }) // Swal
                } // if

                setServerResponse(response_data["json"].message)
                setServerStatus(response_data["status"])
                setShow(true)
            })  // then
            .catch(err => console.log(err))
            reset()

    } // submitForm


    return(

        <div className="signup container">
            <div className="form">

            <h1>Forgot Password Page</h1>

                <form>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Enter email address below</Form.Label>
                        <Form.Control type="text"
                            placeholder="Enter email address"
                            {...register("email", {required:true, maxLength:80})}
                        />
                        {errors.email && <span style={{color:"red"}}>Email is required. </span>}
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <Button as="sub" variant="primary" onClick={handleSubmit(submitForm)}>Reset Password</Button>
                    </Form.Group>
                </form>

                <br/>

                { show ?
                    <>
                    { serverStatus === 201 ?
                        <Alert variant="success" onClose={() => {setShow(false)}} dismissible>
                            {serverResponse}
                        </Alert>
                        :
                        <Alert variant="danger" onClose={() => {setShow(false)}} dismissible>
                            {serverResponse}
                        </Alert>
                     }
                    </>
                    : ''
                }

            </div>
        </div>

    ) // return

}

export default ForgotPasswordPage
