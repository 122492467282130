import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import HomePage from './components/Home';
import LoginPage from './components/Login';
import SignUpPage from './components/SignUp';
import BuilderPage from './components/Builder';
import ForgotPasswordPage from './components/ForgotPassword';
import ResetPasswordPage from './components/ResetPassword';
import UserProfilePage from './components/UserProfile';
import {useAuth} from './auth'

const App=()=>{

    const [logged] = useAuth();

    return(
        <Router>
            <div className="">
                <Navbar/>
                <Switch>
                    <Route exact path="/user_profile">
                        {logged ? <UserProfilePage/>: <LoginPage/>}
                    </Route>
                    <Route exact path="/signup">
                        {!logged ? <SignUpPage/> : ''}
                    </Route>
                    <Route exact path="/login">
                        {!logged ? <LoginPage/> : ''}
                    </Route>
                    <Route exact path="/builder">
                        {logged ? <BuilderPage/> : <LoginPage/>}
                    </Route>
                    <Route exact path="/forgot_password">
                        {!logged ? <ForgotPasswordPage/> : ''}
                    </Route>
                    <Route exact path="/reset_password/:id">
                        {!logged ? <ResetPasswordPage/> : ''}
                    </Route>
                    <Route exact path="/">
                        <HomePage/>
                    </Route>
                </Switch>
            </div>
        </Router>
    )
}


ReactDOM.render(<App/>,document.getElementById('root'));

//
//// If you want to start measuring performance in your app, pass a function
//// to log results (for example: reportWebVitals(console.log))
//// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
