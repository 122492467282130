import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/main.css'
import {useAuth, logout} from '../auth'
import Swal from 'sweetalert2';
import Image from "../images/1.png"
import {useHistory} from 'react-router-dom'

const LoggedInHome = () => {
    return (
        <div style={{minHeight: '90vh', background: "white"}}>
            <div className="home-leftbox">
                <h1 className="heading">Welcome</h1>
                <p>
                    LatticeMixer is an easy to use online tool for building heterostructure models
                    for computational material science.
                </p>
                <Link to="/builder" className='btn btn-primary'>Build Your Structure Now</Link>
                <p>
                    Need a Custom Solution? Inquire At:
                    latticemixer@gmail.com
                </p>
                <h1 className="heading">Current Features:</h1>
                <p>
                    1. Heterostructures for hexagonal (001) / hexagonal (001) systems
                    <br/>
                    2. Heterostructures for cubic (001) / cubic (001) systems
                    <br/>
                    3. Heterostructures for orthorhombic (001) / orthorhombic (001) systems
                </p>
                <h1 className="heading">Upcoming Features:</h1>
                <p>
                    1. Support for Cubic (001, 010) surfaces
                    <br/>
                    2. Support for Tetragonal and Orthorhombic (100, 010, 001) surfaces
                    <br/>
                    3. Support for Cubic (111) surface
                </p>
            </div>
            <div className="home-rightbox">
                <img height="600" width="800" id="image" src={Image}/>
            </div>
        </div>
    )
}


const LoggedOutHome = () => {
    return (
        <div style={{minHeight: '90vh', background: "white"}}>
            <div className="home-leftbox">
                <h1 className="heading">Welcome</h1>
                <p>
                    LatticeMatcher is an easy to use online tool for building heterostructure models
                    for computational material science.
                </p>
                <Link to="/builder" className='btn btn-primary'>Build Your Structure Now</Link>
                <p>
                    Need a Custom Solution? Inquire At:
                    latticemixer@gmail.com
                </p>
                <h1 className="heading">Current Features:</h1>
                <p>
                    1. Heterostructures for hexagonal (001) / hexagonal (001) systems
                    <br/>
                    2. Heterostructures for cubic (001) / cubic (001) systems
                    <br/>
                    3. Heterostructures for orthorhombic (001) / orthorhombic (001) systems
                </p>
                <h1 className="heading">Upcoming Features:</h1>
                <p>
                    1. Support for Cubic (001, 010) surfaces
                    <br/>
                    2. Support for Tetragonal and Orthorhombic (100, 010, 001) surfaces
                    <br/>
                    3. Support for Cubic (111) surface
                </p>
            </div>
            <div className="home-rightbox">
                <img height="600" width="800" id="image" src={Image}/>
            </div>
        </div>
    )
}


const HomePage = () => {

    const [logged] = useAuth()
    const history = useHistory()
    if (logged) {
        // set a timeout for users - 1 hour to logout, and delete structure files
        setTimeout( () => {
            logout()
            history.push('/login')
            Swal.fire({
                title: 'You Have Been Logged Out!',
                text: "You have been logged out due to inactivity. Please log back in.",
                icon: 'warning',
            })
            const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
            // When you make a POST request, you have to encode the data that forms the body of the request in some way.
            // https://stackoverflow.com/questions/4526273/what-does-enctype-multipart-form-data-mean
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': token,
                },
            }
            fetch('/builder/delete', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data.message)
            })
        }, 3600000); // setTimeout, ms
    } // if

    return (
        <div>
            {logged ? <LoggedInHome/> : <LoggedOutHome/>}
        </div>
    )
}

export default HomePage
