import React, {useState} from 'react'
import '../styles/main.css'
import FileUploader from './FileUploader'


const BuilderPage = () => {

    const [name, setName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    return (
        <div className="container">
            <FileUploader/>
        </div>
    );

}

export default BuilderPage;
