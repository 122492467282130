import React, {useState, useRef, fileUpload, setSelectedFile, setName, submitForm, name} from 'react'
import {Form, Button, Dropdown, Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import '../styles/main.css'
import Pagination from './Pagination';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';


const FileUploader = (onFileSelect) => {

    const [userTokenCount, setUserTokenCount] = useState('')

    const [show, setShow] = useState(false)
    const [serverResponse, setServerResponse] = useState('')
    const [serverStatus, setServerStatus] = useState('')

    const [vacuumAxisLength, setVacuumAxisLength] = useState('')
    const [separationDistance, setSeparationDistance] = useState('')
    const [file1, setFile1] = useState('')
    const [file2, setFile2] = useState('')

    const [displayedCrystalInfo1, setDisplayedCrystalInfo1] = useState('Select Contact Crystal Face')
    const [displayedCrystalInfo2, setDisplayedCrystalInfo2] = useState('Select Contact Crystal Face')

    const [crystalInfo1, setCrystalInfo1] = useState()
    const [crystalInfo2, setCrystalInfo2] = useState()

    const [numberStructuresGenerated, setNumberStructuresGenerated] = useState('')
    const [number, setNumber] = useState('')
    const [structuresInfo, setStructuresInfo] = useState('')

    const [isComputed, setIsComputed] = useState(false)
    const [isPreviewed, setIsPreviewed] = useState(false)

    const [resultFileForDownload, setResultFileForDownload] = useState('')

    const submitForm = () => {
        setNumber('')
        let data = new FormData();
        data.append('file1', file1[0])
        data.append('file2', file2[0])
        data.append('vacuumAxisLength', vacuumAxisLength)
        data.append('separationDistance', separationDistance)
        data.append("latticeType1", crystalInfo1["latticeType"])
        data.append("latticeType2", crystalInfo2["latticeType"])
        data.append("latticeFace1", crystalInfo1["contactFace"])
        data.append("latticeFace2", crystalInfo2["contactFace"])
        const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
        // When you make a POST request, you have to encode the data that forms the body of the request in some way.
        // https://stackoverflow.com/questions/4526273/what-does-enctype-multipart-form-data-mean
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: data
        }
        fetch('/builder/upload', requestOptions)
        .then(response =>  response.json().then(response_data => (
            {"status": response.status, "json": response_data}
         )))
        .then(response_data => {
            setNumberStructuresGenerated(response_data["json"].number_of_structures_made)
            setStructuresInfo(response_data["json"].info_about_structures)
            setUserTokenCount(response_data["json"].userTokenCount)
            setServerResponse(response_data["json"].message)
            setServerStatus(response_data["status"])
            setShow(true)
        })
        .then(isComputed => setIsComputed(true))
    }


    const previewStructure = (number) => {
        setNumber(number)
        // When you make a POST request, you have to encode the data that forms the body of the request in some way.
        // https://stackoverflow.com/questions/4526273/what-does-enctype-multipart-form-data-mean
        const requestOptions = {
            method: 'GET',
        }
        fetch('/builder/preview/'+number, requestOptions)
        .then(response => response.blob()).then((blob) => {
            setResultFileForDownload(URL.createObjectURL(blob))
            const outurl = URL.createObjectURL(blob);
            fetch(outurl)
            .then(res => res.text())
            .then(data => {
                console.log("data = ", data)
                window.parent.playerdata = data
            })
        })
        setIsPreviewed(true)
    }


    const downloadStructure = () => {

        if (userTokenCount <= 0) {
            Swal.fire({
                title: 'Not Enough Tokens!',
                text: "Please load more tokens into you account",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK, got it!'
            })
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "File downloads cost 1.0 tokens. Are you sure you want to proceed?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, download it!',
        }).then((result) => {
            if (result.isConfirmed) {

                saveAs(resultFileForDownload, 'heterostructure.vasp')

                Swal.fire({
                    title: "Saving",
                    text: "Heterostructure has been downloaded!",
                    timer: 2000,
                    showConfirmButton: false,
                    type: "success"
                });

                const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
                // When you make a POST request, you have to encode the data that forms the body of the request in some way.
                // https://stackoverflow.com/questions/4526273/what-does-enctype-multipart-form-data-mean
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': token,
                    },
                }
                fetch('/builder/download', requestOptions)
                .then(response => response.json())
                .then(data => {
                    setUserTokenCount(data.userTokenCount)
                })

            } // if
        }) // then
    } // downloadStructure


    const views = [];
    if (isComputed) {
        for (let i = 0; i < numberStructuresGenerated; i++) {
            var latticeMisMatch_a = structuresInfo[i].latticeMisMatchAsPercent[0].toFixed(2)
            var latticeMisMatch_b = structuresInfo[i].latticeMisMatchAsPercent[1].toFixed(2)
            var numberOfAtoms = structuresInfo[i].numberOfAtoms
            var cells_info = structuresInfo[i].cells_info

            var cell_value_1_a = cells_info.system1[1]
            var cell_value_1_b = cells_info.system1[2]
            var cell_value_2_a = cells_info.system2[1]
            var cell_value_2_b = cells_info.system2[2]
            if (cells_info.system1[0] === "root") {
                var cell_1_a_result = <a>&#8730;{cell_value_1_a}</a>
                var cell_1_b_result = <a>&#8730;{cell_value_1_b}</a>
            }
            else {
                var cell_1_a_result = <a>{cell_value_1_a}</a>
                var cell_1_b_result = <a>{cell_value_1_b}</a>
            }
            if (cells_info.system2[0] === "root") {
                var cell_2_a_result = <a>&#8730;{cell_value_2_a}</a>
                var cell_2_b_result = <a>&#8730;{cell_value_2_b}</a>
            }
            else {
                var cell_2_a_result = <a>{cell_value_2_a}</a>
                var cell_2_b_result = <a>{cell_value_2_b}</a>
            }

            views.push(
                <div>
                    <div>
                    <b>Heterostructure File {i+1}:</b><br/>
                    <a>Lattice Mismatch in [a,b]: [{latticeMisMatch_a}%, {latticeMisMatch_b}%]</a><br/>
                    <a>Number Of Atoms: {numberOfAtoms}</a><br/>
                    <a>System #1: {cell_1_a_result} x {cell_1_b_result}</a><br/>
                    <a>System #2: {cell_2_a_result} x {cell_2_b_result}</a><br/><br/>

                    </div>
                    <div class="btn-toolbar">
                        <a href="#" class="btn btn-success" onClick={() => previewStructure(i)}>Preview</a>
                        <div class="divider"/>
                        {(i === number) ?
                               <button id='download' class="btn btn-primary" onClick={() => downloadStructure()}>Download</button>
                        :
                        ""}
                        <div class="divider"/>
                    </div>
                    <br></br>
                </div>

            );
        } // for=loop
    } // if

    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage, setResultsPerPage] = useState(3);
    const indexOfLastResult = currentPage * resultsPerPage
    const indexOfFirstResult = indexOfLastResult - resultsPerPage
    const currentResults = views.slice(indexOfFirstResult, indexOfLastResult)
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (

        <div>

            <div className="leftbox">
                <h3>Insert Variables</h3>

                <form>

                    <Form.Group className="mb-3">
                        <Form.Label>Vacuum Axis Length (Angstrom)</Form.Label>
                        <Form.Control type="text"
                            placeholder="e.g. 35.0"
                            value={vacuumAxisLength}
                            name="vacuumAxisLength"
                            onChange={(event)=>{
                                setVacuumAxisLength(event.target.value);
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Separation Distance  (Angstrom)</Form.Label>
                        <Form.Control type="text"
                            placeholder="e.g. 8.0"
                            value={separationDistance}
                            name="separationDistance"
                            onChange={(event)=>{
                                setSeparationDistance(event.target.value);
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}
                        />
                    </Form.Group>

                    <Form.Label>System #1</Form.Label>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>File Upload</Form.Label>
                        <Form.Control type="file" onChange={(event)=>{setFile1(event.target.files);
                            setIsComputed(false);
                            setIsPreviewed(false)}}
                            />
                    </Form.Group>

                    <Dropdown className="mb-3">
                        <Form.Label>Lattice Type + Contact Crystal Face</Form.Label>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {displayedCrystalInfo1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                            <Dropdown.Item href="#/action-1" onClick={()=>{
                                setCrystalInfo1({"latticeType": "hexagonal", "contactFace": "001"});
                                setDisplayedCrystalInfo1("hexagonal - 001");
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}>
                                hexagonal - 001
                            </Dropdown.Item>

                            <Dropdown.Item href="#/action-1" onClick={()=>{
                                setCrystalInfo1({"latticeType": "cubic", "contactFace": "001"});
                                setDisplayedCrystalInfo1("cubic - 001");
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}>
                                cubic - 001
                            </Dropdown.Item>

                            <Dropdown.Item href="#/action-1" onClick={()=>{
                                setCrystalInfo1({"latticeType": "orthorhombic", "contactFace": "001"});
                                setDisplayedCrystalInfo1("orthorhombic - 001");
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}>
                                orthorhombic - 001
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>

                    <Form.Label>System #2</Form.Label>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>File Upload</Form.Label>
                        <Form.Control type="file" onChange={(event)=>{setFile2(event.target.files); setIsComputed(false); setIsPreviewed(false)}}/>
                    </Form.Group>

                    <Dropdown className="mb-3">
                        <Form.Label>Lattice Type + Contact Crystal Face</Form.Label>
                        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                            {displayedCrystalInfo2}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                            <Dropdown.Item href="#/action-1" onClick={()=>{
                                setCrystalInfo2({"latticeType": "hexagonal", "contactFace": "001"});
                                setDisplayedCrystalInfo2("hexagonal - 001");
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}>
                                hexagonal - 001
                            </Dropdown.Item>

                            <Dropdown.Item href="#/action-1" onClick={()=>{
                                setCrystalInfo2({"latticeType": "cubic", "contactFace": "001"});
                                setDisplayedCrystalInfo2("cubic - 001");
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}>
                                cubic - 001
                            </Dropdown.Item>

                            <Dropdown.Item href="#/action-1" onClick={()=>{
                                setCrystalInfo2({"latticeType": "orthorhombic", "contactFace": "001"});
                                setDisplayedCrystalInfo2("orthorhombic - 001");
                                setIsComputed(false);
                                setIsPreviewed(false);
                            }}>
                                orthorhombic - 001
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>

                    <Form.Group>
                        <Button as="sub" variant="primary" onClick={submitForm}> Submit</Button>

                    </Form.Group>

                    <br></br>

                </form>

                { show ?
                    <>
                        { serverStatus == 201 ?
                            <Alert variant="success"
                                isOpen={window.setTimeout(()=>{setShow(false)},5000)}
                                onClose={() => {setShow(false)}} dismissible
                            >
                                {serverResponse}
                            </Alert>
                        :
                            <Alert variant="danger"
                                isOpen={window.setTimeout(()=>{setShow(false)},5000)}
                                onClose={() => {setShow(false)}} dismissible
                            >
                                {serverResponse}
                            </Alert>
                        }
                    </>
                    :
                    ""
                }

            </div>

            <div className="middlebox">
                {serverStatus === 201 ?
                    <div>
                    {isComputed ? <h3>Computed Structures</h3> : ''}
                    {isComputed ?
                        <div>
                        <Pagination
                            currentResults={currentResults}
                            resultsPerPage={resultsPerPage}
                            totalResults={numberStructuresGenerated}
                            paginate={paginate}
                        />
                        <h4>Number of Tokens: {userTokenCount}</h4>
                        </div>
                    :''}
                    </div>
                :''}
            </div>

            <div className="rightbox">
                {isPreviewed ?
                    <iframe key={number} src="./player.html" scrolling="no" height="600" width="600"></iframe>
                : ''
                }

            </div>

        </div>
    )
}

export default FileUploader;
