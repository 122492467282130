import React, {useState} from 'react'
import {Form, Button, Alert} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {login} from '../auth.jsx'
import {useHistory} from 'react-router-dom'


const SignUpPage = () => {

    // initial username is an empty string. When we want to update our username, we set the username to something
    // do the same thing for email and password, etc.
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    // using the react-hook-form
    const {register, watch, handleSubmit, reset, formState:{errors}} = useForm();

    const [show, setShow] = useState(false)
    const [serverResponse, setServerResponse] = useState('')

    // use react-router to redirect to another page - just push the next page to this history
    const history = useHistory()

    const submitForm = (data) => {

        if (data.password === data.confirmPassword) {

            const body = {
                username: data.username,
                email: data.email,
                password: data.password
            }

            const requestOptions = {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body)
            }

            fetch('/auth/signup', requestOptions)
                .then(response =>  response.json().then(response_data => (
                    {"status": response.status, "json": response_data}
                 )))
                .then(response_data => {
                    console.log(response_data["json"])
                    setServerResponse(response_data["json"].message)
                    setShow(true)
                    console.log("response_status = ", response_data["status"])
                    if (response_data["status"] === 201) {
                        const body = {
                            username: data.username,
                            password: data.password
                        }
                        const requestOptions = {
                            method: "POST",
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        }
                        fetch('/auth/login', requestOptions)
                        .then(response2 => response2.json())
                        .then(data2 => {
                            console.log(data2.access_token)
                            if (data2) {
                                login(data2.access_token)
                                history.push('/')
                            }
                        })
                    }
                })
                .catch(err => console.log(err))
            reset()

        } // if

        else {
            alert("Passwords do not match")
        } // else


    }

    // if one wants to customize the serverResponse below, you need to go into the backend and change the server
    // response for a given API call case.
    return (
        <div className="signup container">
            <div className="form">

                { show ?
                    <>
                        <Alert variant="success" onClose={() => {setShow(false)}} dismissible>
                            {serverResponse}
                        </Alert>
                        <h1>Sign Up Page</h1>
                    </>
                    :
                    <h1>Sign Up Page</h1>
                }


                <form>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text"
                            placeholder="Your username"
                            {...register("username", {required:true, maxLength:25})}
                        />
                        {errors.username && <span style={{color:"red"}}>Username is required. </span>}
                        {errors.username?.type==="maxLength" && <span style={{color:"red"}}>
                                                    Username must be less that 25 characters. </span>}
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text"
                            placeholder="Your email"
                            {...register("email", {required:true, maxLength:80})}
                        />
                        {errors.email && <span style={{color:"red"}}>Email is required. </span>}
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"
                            placeholder="Your password"
                            {...register("password", {required:true, minLength:8})}
                        />
                        {errors.password && <span style={{color:"red"}}>Password is required. </span>}
                        {errors.password?.type==="minLength" && <span style={{color:"red"}}>
                                                    Password must be at least 8 characters. </span>}
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password"
                            placeholder="Your confirmed password"
                            {...register("confirmPassword", {required:true, minLength:8})}
                        />
                        {errors.confirmPassword && <span style={{color:"red"}}>Must confirm password. </span>}
                        {errors.confirmPassword?.type==="minLength" && <span style={{color:"red"}}>
                                                    Password must be at least 8 characters. </span>}
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <Button as="sub" variant="primary" onClick={handleSubmit(submitForm)}>Sign Up</Button>
                    </Form.Group>
                    <br></br>
                    <Form.Group>
                        <small>Already have an account? <Link to="/login">Login</Link></small>
                    </Form.Group>
                </form>
            </div>
        </div>
    )
}

export default SignUpPage