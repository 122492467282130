import React, { useContext } from 'react';
import {Nav, Offcanvas, Container, Form, FormControl, Button, NavDropdown} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth, logout} from '../auth'
import Swal from 'sweetalert2';

const LoggedInLinks = () => {
    return (
        <>
            <li className="nav-item">
                <Link className="nav-link active" to="/">Home</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to="/builder">Builder</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to="/user_profile">User Profile</Link>
            </li>
            <li className="nav-item">
                <a className="nav-link active" href="/" onClick={()=>{
                    logout()
                    const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Authorization': token,
                        }, // headers
                    } // requestOptions
                    fetch('/builder/delete', requestOptions)
                    .then(response => response.json())
                }}
                >Log Out</a>
            </li>
        </>
    )
}

const LoggedOutLinks = () => {
    return (
        <>
            <li className="nav-item">
                <Link className="nav-link active" to="/">Home</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to="/signup">Sign Up</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link active" to="/login" >Login</Link>
            </li>

        </>
    )
}

const Navbar = () => {

    const [logged] = useAuth();

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">LatticeMixer</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        {logged?<LoggedInLinks/>:<LoggedOutLinks/>}
                    </ul>
                </div>
                {logged?<a className="navbar-brand">Signed In</a>:<a className="navbar-brand">Signed Out</a>}
            </div>
        </nav>
    )

}

export default Navbar
