import {createAuthProvider} from 'react-token-auth'

// https://github.com/obabichev/react-token-auth
export const {useAuth, authFetch, login, logout} =
    createAuthProvider({
        accessTokenKey: 'access_token',
        onUpdateToken: (token) => fetch('/auth/refresh',
        {
            method: 'POST',
            body: token.refresh_token
        })
        .then(response => response.json())
})